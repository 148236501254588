import React from "react";
import logo from './asssets/img/backgrounds/background.png'
import instagram from './asssets/instagram.png'
import linkedin from './asssets/linkedin.png'
import behance from './asssets/behance.png'
import './styles.css'
import { Avatar, Box } from "@mui/material";


function App() {
  return (
    <div className="container-main">
      {/* <Stack flexDirection="column" alignItems="center" justifyContent="center" pt={4} sx={{fontSize: 12}}> */}
        <div className="image" >
          <a href="https://www.behance.net/CPHAN" target="_blank" rel="noreferrer">
            <img src={logo} alt="christina phan images" />
          </a>
        </div>

        <div className="box-flex">
          <div>
            <a href="https://www.behance.net/CPHAN" target="_blank" rel="noreferrer">
              <Box >
                <Avatar variant="square" alt="behance" src={behance}  color='red' sx={{ width: 25, height: 25, }}/>
              </Box>
            </a>
          </div>
          <div>
            <a href="https://www.instagram.com/c.phan/?hl=en" target="_blank" rel="noreferrer">
              <Box >
                <Avatar variant="square" alt="instagram" src={instagram}  color='red' sx={{ width: 25, height: 25, }}/>
              </Box>
            </a>
          </div>
          <div>
            <a href="https://www.linkedin.com/mwlite/in/cphan" target="_blank" rel="noreferrer">
              <Box >
                <Avatar variant="square" alt="linkedin" src={linkedin}  color='red' sx={{ width: 25, height: 25, }}/>
              </Box>
            </a>
          </div>
        </div>
      {/* </Stack> */}
    </div>
  );
}

// Stack sx={{
//   backgroundColor: '#ccc',
//   height: 65,
//   color: '#000',
//   fontSize: 12,
//   margin: 0
// }}>
//  <Stack flexDirection="row" alignItems="center" justifyContent="center" pt={3} >
//     <a href="https://behance.net/" target="_blank" rel="noreferrer">
//       <Stack pl={1} pr={1} flexDirection="row" alignItems="center">
//         <Avatar variant="square" alt="behance" src={behance}  color='red' sx={{ width: 20, height: 20, }}/>
//         <Box pl={1}>Behance</Box>
//       </Stack>
//     </a>
//     <a href="https://instagram.com/" target="_blank" rel="noreferrer">
//       <Stack pl={1} pr={1} flexDirection="row" alignItems="center">
//         <Avatar variant="square" alt="instagram" src={instagram}  color='red' sx={{ width: 20, height: 20, }}/>
//         <Box pl={1}>Instagram</Box>
//       </Stack>
//     </a>
//     <a href="https://linkedin.com/" target="_blank" rel="noreferrer">
//       <Stack pl={1} pr={1} flexDirection="row" alignItems="center">
//         <Avatar variant="square" alt="linkedin" src={linkedin}  color='red' sx={{ width: 20, height: 20, }}/>
//         <Box pl={1}>Linkedin</Box>
//       </Stack>
//     </a>
//     <a href="mailto:cphan@christinaphan.com" target="_blank" rel="noreferrer">
//       <Stack pl={1} pr={1} flexDirection="row" alignItems="center">
//           <Avatar variant="square"  alt="mail" src={mail}  color='red' sx={{ width: 20, height: 20, }}/>
//           <Box pl={1}>E-Mail</Box>
//       </Stack>
//     </a>
    
//  </Stack>
// </Stack>

export default App;
